/*General Rules*/
  * {
    box-sizing: border-box;
  }

  img {
    width: 3vw;
    height: 3vw;
    image-rendering: pixelated;
    position: relative;
    top: 2vh;
  }
  #simimg {
    width: 9vw;
    height: 3vw;
    image-rendering: pixelated;
  }
  #questimg {
    width: 9vw;
    height: 3vw;
    image-rendering: pixelated;
  }

/*General Font*/
  @font-face{
    font-family:'IBM Plex Sans Condensed', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400&display=swap'), format('truetype');
  }

/*Wrapper Rules*/
  .wrapper{
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(9, 1fr);
    width: 100vw;
    height: 100vh;
  }

  .backgroundimage{
    width: 100vw;
    height: 100vh;
    background-size: 100% 100%;
    position: absolute;
    background-image: url(./Images/ganbgshift.png);
    image-rendering: pixelated;
    z-index: -1;
    transition-duration: 1s;
  }

/* Modal Rules */
  .Modal {
    position: absolute;
    top: 15vh;
    left: 20vw;
    right: 20vw;
    bottom: 15vh;
    background-color: black;
    transition: background-color 2s ease;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  .Modal_Text {
    overflow: visible;
    align-self: flex-start;
    color: white;
    text-shadow: 2px 2px 5px black;
    font-family:'IBM Plex Sans Condensed';
    filter: url("#dialoguedisp");
    display: inline-block;
    font-size: 1.5vw;
  }
  .Warning_Text {
    overflow: visible;
    align-self: flex-start;
    color: white;
    font-family:'IBM Plex Sans Condensed';
    display: inline-block;
    font-size: 1.25vw;
  }
  #warningId{
    opacity: 0;
    font-size: 1.25vw;
  }
  #acknowledgementId{
    opacity: 1;
    font-size: 1.25vw;
    display: none;
  }
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  #info_modal {
    position: absolute;
    top: 15vh;
    left: 20vw;
    right: 20vw;
    bottom: 15vh;
    background-color: #5252ff;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  #infoModalTitle{
    font-size: 3.0vw;
    color: white;
  }
  .infoModalText{
    font-size: 1.5vw;
    color: white;
    line-height: 3vw;
    margin-left: 3vw; 
  }
  .infoModalTextSmall{
    font-size: 1.5vw;
    color: white;
    line-height: 3vw;
    margin-left: 9vw; 
  }
  /* Mobile Text */
  #mobiletext { 
    display: none;
    color: white; }
    
  /* Loading Bar */
    #loadingText {
      position: absolute;
      top: 50%;
      left: 30%;
      text-align: center;
      text-justify: center;
      z-index: 10;
      user-select: none;
      color: white;
      font-size: 5.0vw;
      text-shadow: 2px 2px 5px black;
      font-family:'IBM Plex Sans Condensed';
      filter: url("#dialoguedisp");
    }
    #progress {
      height: 10px;
      background: gray;
      position: absolute;
      top: 45%;
      left: 0%;
      opacity: 1;
      z-index: 2;
      transition: width 0.2s ease;
      filter: url("#dialoguedisp");
    }
    .fadeOut {
      opacity: 0 !important;
      transition: opacity 1.25s !important;
    }
    .fadeInButton {
      opacity: 1 !important;
      transition: opacity ease-in-out 2s !important;
    }
    /* Modal Button Styling */
    #continuebutton{
      position: absolute;
      bottom: 3%;
      left: 2%;
      background-image: url(./Images/Buttons/butt_cont.png);
      background-size: 100% 100%;
      background-position: center;
      filter: url("#turbdisp12");
      opacity: 0;
      display: none;
    }
    #warningcontinuebutton{
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      border-right: 1px solid #848484;
      border-bottom: 1px solid #848484;
      background-color: transparent;
      padding: 3px 15px 3px 15px;
      width: 9vw;
      height: 3vw;
      font-size: 1.0vw;
      color: white;
      user-select: none;
      position: absolute;
      bottom: 10vh;
      left: 2vw;
    }
    #acontinuebutton{
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      border-right: 1px solid #848484;
      border-bottom: 1px solid #848484;
      background-color: transparent;
      padding: 3px 15px 3px 15px;
      width: 9vw;
      height: 3vw;
      font-size: 1.0vw;
      color: white;
      user-select: none;
      position: absolute;
      bottom: 10vh;
      left: 2vw;
    }
    #modalTitle{
      opacity: 0;
      display: none;
    }

  /*Painter Rules*/
    .painter{
      grid-column: 3/17;
      grid-row: 2/10;
      align-self: left;
      justify-self: left;
      overflow: hidden;
      cursor: url(./Images/airbrush_cursor.png), auto;
    }

/*Dialogue Rules*/
  .dialogueBar{
    grid-column: 3/17;
    grid-row: 1/2;
    z-index: 3;
    overflow: hidden;
    align-self: center;
  }
  .dialogueText{
    padding: 2.0vh;
    margin: 0.0625em;
    max-width: 100%;
    line-height: 100%;
    align-self: center;
    background-color: #5252ff;
    color: white;
    text-shadow: 2px 2px 5px black;
    font-family:'IBM Plex Sans Condensed';
    filter: url("#dialoguedisp");
    display: inline-block;
    font-size: 2.75vw;
    box-shadow: 0 0 0 0.1vh black;
    user-select: none;
  }
  #dialogueId{
    opacity: 0.0;
  }
  /*Dialogue Typing*/
  .modal-type{
    font-family:'IBM Plex Sans Condensed';
  }
  /* TypeWriter Cursor */
    .modal-type > .txt {
      border-right: 1vw solid black;
      animation: 0.53s cursor step-end infinite;
      animation-fill-mode: forwards;
      text-shadow: 2px 2px 5px black;
    }

  /*Dialogue Typing*/
  .txt-type{
    font-family:'IBM Plex Sans Condensed';
  }
  /* TypeWriter Cursor */
    .txt-type > .txt {
      border-right: 1vw solid white;
      animation: 0.53s cursor step-end infinite;
      animation-fill-mode: forwards;
      text-shadow: 2px 2px 5px black;
    }
    @keyframes cursor {
      from, to {
        border-color: white;
      }
      50% {
        border-color: transparent;
      }
    }

/*VA Rules*/
  .virtualAssistant{
    font-family:'IBM Plex Sans Condensed';
    font-size: 10px;
    grid-column: 1/3;
    grid-row: 1/2;
    /*overflow: hidden;*/
    background-size: 100% 100%;
    background-position: center;
    /*background-image: url(./Images/VA_BG.png);*/
  }

/*Settings Rules*/
.settings_container{
  height: 50%;
  width: 100%;
  align-self: left;
  justify-self: left;
}
.settingsBar{
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-image: url(./Images/settingsbg.png);
  grid-column: 1/3;
  grid-row: 2/10;
  overflow: hidden;

}
#settingsTitle{
  top: 15%;
  left: 3%;
  position: absolute;
  padding: 0vh;
  margin: 0;
  max-width: 100%;
  align-self: flex-start;
  background-color: #5252ff;
  color: white;
  text-shadow: 2px 2px 5px black;
  font-family:'IBM Plex Sans Condensed';
  filter: url("#dialoguedisp");
  display: inline-block;
  font-size: 4.0vh;
  box-shadow: 0 0 0 0.1vh black;
  user-select: none;
}
/* Settings Button Styling */

/* Button Text */
  button {
    background-color: transparent;
    transition: 0.2s;
    border: none;
    outline:none;
  }
  button:disabled,
  button[disabled]{
    opacity: 0.5;
    mix-blend-mode: difference;
    transition: 0.2s;
  }
  .settings_button {
    /* Hide Button Text Content */
    color:rgba(0,0,0,0);
    width: 4vw;
    height: 4vw;
    user-select: none;
  }
  .painter_button {
    /* Hide Button Text Content */
    color:rgba(0,0,0,0);
    width: 15vw;
    height: 5vw;
    user-select: none;

  }
  .modal_button {
    /* Hide Button Text Content */
    color:rgba(0,0,0,0);
    width: 15vw;
    height: 5vw;
    user-select: none;
  }
/* On Hover */
  #button1:hover {
    z-index: 100;
    transition-duration: 1s;
  }
/* Individual Buttons */
  #button1{
    position: relative;
    top: 22%;
    left: 0%;
    background-image: url(./Images/Buttons/butt01.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp1");
  }
  #button2{
    position: relative;
    top: 31%;
    left: 22%;
    background-image: url(./Images/Buttons/butt02.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp2");
  }
  #button3{
    position: relative;
    top: 52%;
    left: -59%;
    background-image: url(./Images/Buttons/butt03.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp3");
  }
  #button4{
    position: relative;
    top: 54%;
    left: 55%;
    background-image: url(./Images/Buttons/butt04.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp4");
  }
  #button5{
    position: relative;
    top: 70%;
    left: -32%;
    background-image: url(./Images/Buttons/butt05.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp5");
  }
  #button6{
    position: relative;
    top: 87%;
    left: 0%;
    background-image: url(./Images/Buttons/butt06.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp6");
  }
  #button7{
    position: relative;
    top: 87%;
    left: 10%;
    background-image: url(./Images/Buttons/butt07.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp7");
  }
  #button8{
    position: relative;
    top: 105%;
    left: 12%;
    background-image: url(./Images/Buttons/butt08.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp8");
  }
  #button9{
    position: relative;
    top: 130%;
    left: -57%;
    background-image: url(./Images/Buttons/butt09.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp9");
  }
  #button_sim{
    position: absolute;
    bottom: 2%;
    right: 17%;
    background-image: url(./Images/Buttons/buttsim.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp10");
  }
  #button_quest{
    position: absolute;
    bottom: 2%;
    right: 1%;
    background-image: url(./Images/Buttons/buttquest.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp11");
  }
  #button_inst{
    position: absolute;
    top: 12%;
    right: 1.7%;
    width: 15vw;
    height: 3.75vw;
    background-image: url(./Images/Buttons/buttinst.png);
    background-size: 100% 100%;
    background-position: center;
    filter: url("#turbdisp13");
  }

/*Mobile Rule*/
@media all and (any-hover: none) {
  /* custom css for "no hover targets" */
  #continuebutton { display: none; }
  .Overlay { background-color: black;}
  .Modal_Text { display: none; }
  .Warning_Text { display: none; }
  #mobiletext { display: inline-block; }
  #progress { display: none; }
}





